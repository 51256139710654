<template>
  <div class="flix-row">
    <div class="flix-col-md-6">
      <h4 class="flix-html-h4">{{ $t('message.editNumber') }}</h4>
      {{ data.booking_id }}
      <!-- <div v-if="JSON.parse(data.atitle).user !== '07a4e20a7bbeeb7a736682b26b16ebe8'"> -->

      <div>
        <h4 class="flix-html-h4">
          {{ data.group || 1 }}
          {{ $tc('message.appointments', data.group || 1) }}:
          {{ JSON.parse(data.atitle).title }}
        </h4>
        <div class="flix-form-group">
          <bookingflix-calendar-items
            style="display: block; margin-top: 5px"
            :settings="settings"
          ></bookingflix-calendar-items>

          <div class="flix-html-small" style="display: block; margin-top: 5px">
            <bookingflix-calendar-items
              style="display: inline-block"
              :settings="settings2"
            ></bookingflix-calendar-items>
          </div>
        </div>
      </div>

      <div>
        <h4 class="flix-html-h4">{{ $t('message.editStatus') }}</h4>
        <div class="flix-form-group">
          <span
            :class="
              'flix-html-h2 flix-bg-' +
              $getStatusClass(data.status) +
              ' flix-text-' +
              $getStatusClass(data.status)
            "
            style="padding: 5px"
          >
            <span
              v-if="
                data.status === 'commitment' || data.status === 'cancellation'
              "
              >{{ $tc('message.appointments', 1) }}-</span
            >{{ $t('message.' + data.status) }}
            <flixIcon v-if="data.status === 'commitment'" id="ok" /><flixIcon
              v-if="data.status === 'cancellation'"
              id="remove"
            />
          </span>
        </div>
        <div class="flix-form-group" v-if="data.status === 'open'">
          <span class="flix-alert flix-alert-info">{{
            $t('message.appointmentOpenMsg')
          }}</span>
        </div>
      </div>
      <note :data="data" />
    </div>
    <div class="flix-col-md-6">
      <div
        class="flix-form-group"
        v-if="data.validated && data.status === 'commitment'"
      >
        <icalDownload
          :className="{ 'flix-btn flix-btn-default flix-btn-xs': true }"
          v-if="data"
          :entry="data"
          :assistent="JSON.parse(data.atitle)"
        />
      </div>
      <div
        class="flix-form-group"
        v-if="data.validated && data.status === 'commitment'"
      >
        <pdf :data="data" :assistent="JSON.parse(data.atitle)" />
      </div>
      <div
        class="flix-form-group"
        v-if="data.validated && data.status === 'commitment'"
      >
        <pdfPrint :data="data" :assistent="JSON.parse(data.atitle)" />
      </div>
      <div class="flix-form-group">
        <location :data="data" />
        <div
          v-if="
            checkWebinar() && data.validated && data.status === 'commitment'
          "
        >
          <router-link
            class="flix-btn flix-btn-success"
            :to="getLink('booking-webinar')"
            ><flixIcon :id="'webcam'" />
            {{ $tc('message.webinar', 1) }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="flix-col-md-12 flix-text-center" style="margin-top: 40px">
      <div class="flix-well">
        <saveBtn :onClick="setOpenLink">
          <span slot="icon"><flixIcon class="icon" id="lock" /></span>
          <span slot="text">{{ $t('overview.login') }}</span>
        </saveBtn>
        <div style="margin-top: 10px">
          {{ $t('overview.or') }}
          <a
            class="flix-html-a flix-text-success"
            style="font-weight: bold"
            href="#"
            @click.prevent="setAccountLink"
            ><flixIcon class="icon" id="keys" />
            {{ $t('overview.register') }}</a
          >
        </div>
      </div>
      <h4 class="flix-html-h4" style="margin-top: 50px">
        {{ $t('overview.title') }}
      </h4>
      <ul class="flix-list-group">
        <li
          class="flix-list-group-item"
          v-for="arg in $t('overview.benefits')"
          :key="arg"
        >
          <flixIcon id="ok" /> {{ arg }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    note() {
      return import('@/components/bookingInfoPage/details/note')
    },
    location() {
      return import('@/components/bookingInfoPage/details/location')
    },
    icalDownload() {
      return import('@/components/ical/index')
    },
    pdf() {
      return import('@/components/bookingInfoPage/components/pdf')
    },
    pdfPrint() {
      return import('@/components/bookingInfoPage/components/pdfPrint')
    }
  },
  props: {
    data: Object
  },
  data() {
    return {
      settings: JSON.stringify({
        lang: this.$i18n.locale,
        view: 'date-string',
        date: [this.data.begin, this.data.end]
      }),
      settings2: JSON.stringify({
        lang: this.$i18n.locale,
        view: 'timer',
        date: [this.data.begin, this.data.begin]
      })
    }
  },
  methods: {
    setOpenLink() {
      let lang = ''

      if (this.$route.params.lang !== 'de') {
        lang = '/' + this.$route.params.lang
      }
      window.open(
        process.env.VUE_APP_CLIENTLOGIN +
          lang +
          '/login/' +
          btoa(
            JSON.stringify({
              email: this.data.email,
              redirect:
                '/dashboard/details/' +
                this.data.booking_id +
                '/booking-details/dates'
            })
          ),
        '_self'
      )
    },
    setAccountLink() {
      let lang = ''

      if (this.$route.params.lang !== 'de') {
        lang = '/' + this.$route.params.lang
      }
      window.open(
        process.env.VUE_APP_CLIENTLOGIN +
          lang +
          '/lost-password/' +
          btoa(JSON.stringify({ email: this.data.email })),
        '_self'
      )
    },
    getGroupTxt(entry) {
      return this.$getGroupTxt(entry, this)
    },
    getLink(link) {
      var def = {
        name: this.$route.name,
        params: Object.assign(JSON.parse(JSON.stringify(this.$route.params)))
      }
      def.params.page = link
      return def
    },
    checkWebinar() {
      var data = JSON.parse(this.data.atitle)
      if (typeof data.webinar === 'undefined' || !data.webinar) {
        return false
      }
      return true
    }
  },
  mounted() {},
  computed: {}
}
</script>
<style lang="sass" scoped></style>
