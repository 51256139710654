var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flix-row"},[_c('div',{staticClass:"flix-col-md-6"},[_c('h4',{staticClass:"flix-html-h4"},[_vm._v(_vm._s(_vm.$t('message.editNumber')))]),_vm._v(" "+_vm._s(_vm.data.booking_id)+" "),_c('div',[_c('h4',{staticClass:"flix-html-h4"},[_vm._v(" "+_vm._s(_vm.data.group || 1)+" "+_vm._s(_vm.$tc('message.appointments', _vm.data.group || 1))+": "+_vm._s(JSON.parse(_vm.data.atitle).title)+" ")]),_c('div',{staticClass:"flix-form-group"},[_c('bookingflix-calendar-items',{staticStyle:{"display":"block","margin-top":"5px"},attrs:{"settings":_vm.settings}}),_c('div',{staticClass:"flix-html-small",staticStyle:{"display":"block","margin-top":"5px"}},[_c('bookingflix-calendar-items',{staticStyle:{"display":"inline-block"},attrs:{"settings":_vm.settings2}})],1)],1)]),_c('div',[_c('h4',{staticClass:"flix-html-h4"},[_vm._v(_vm._s(_vm.$t('message.editStatus')))]),_c('div',{staticClass:"flix-form-group"},[_c('span',{class:'flix-html-h2 flix-bg-' +
            _vm.$getStatusClass(_vm.data.status) +
            ' flix-text-' +
            _vm.$getStatusClass(_vm.data.status),staticStyle:{"padding":"5px"}},[(
              _vm.data.status === 'commitment' || _vm.data.status === 'cancellation'
            )?_c('span',[_vm._v(_vm._s(_vm.$tc('message.appointments', 1))+"-")]):_vm._e(),_vm._v(_vm._s(_vm.$t('message.' + _vm.data.status))+" "),(_vm.data.status === 'commitment')?_c('flixIcon',{attrs:{"id":"ok"}}):_vm._e(),(_vm.data.status === 'cancellation')?_c('flixIcon',{attrs:{"id":"remove"}}):_vm._e()],1)]),(_vm.data.status === 'open')?_c('div',{staticClass:"flix-form-group"},[_c('span',{staticClass:"flix-alert flix-alert-info"},[_vm._v(_vm._s(_vm.$t('message.appointmentOpenMsg')))])]):_vm._e()]),_c('note',{attrs:{"data":_vm.data}})],1),_c('div',{staticClass:"flix-col-md-6"},[(_vm.data.validated && _vm.data.status === 'commitment')?_c('div',{staticClass:"flix-form-group"},[(_vm.data)?_c('icalDownload',{attrs:{"className":{ 'flix-btn flix-btn-default flix-btn-xs': true },"entry":_vm.data,"assistent":JSON.parse(_vm.data.atitle)}}):_vm._e()],1):_vm._e(),(_vm.data.validated && _vm.data.status === 'commitment')?_c('div',{staticClass:"flix-form-group"},[_c('pdf',{attrs:{"data":_vm.data,"assistent":JSON.parse(_vm.data.atitle)}})],1):_vm._e(),(_vm.data.validated && _vm.data.status === 'commitment')?_c('div',{staticClass:"flix-form-group"},[_c('pdfPrint',{attrs:{"data":_vm.data,"assistent":JSON.parse(_vm.data.atitle)}})],1):_vm._e(),_c('div',{staticClass:"flix-form-group"},[_c('location',{attrs:{"data":_vm.data}}),(
          _vm.checkWebinar() && _vm.data.validated && _vm.data.status === 'commitment'
        )?_c('div',[_c('router-link',{staticClass:"flix-btn flix-btn-success",attrs:{"to":_vm.getLink('booking-webinar')}},[_c('flixIcon',{attrs:{"id":'webcam'}}),_vm._v(" "+_vm._s(_vm.$tc('message.webinar', 1)))],1)],1):_vm._e()],1)]),_c('div',{staticClass:"flix-col-md-12 flix-text-center",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"flix-well"},[_c('saveBtn',{attrs:{"onClick":_vm.setOpenLink}},[_c('span',{attrs:{"slot":"icon"},slot:"icon"},[_c('flixIcon',{staticClass:"icon",attrs:{"id":"lock"}})],1),_c('span',{attrs:{"slot":"text"},slot:"text"},[_vm._v(_vm._s(_vm.$t('overview.login')))])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.$t('overview.or'))+" "),_c('a',{staticClass:"flix-html-a flix-text-success",staticStyle:{"font-weight":"bold"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setAccountLink.apply(null, arguments)}}},[_c('flixIcon',{staticClass:"icon",attrs:{"id":"keys"}}),_vm._v(" "+_vm._s(_vm.$t('overview.register')))],1)])],1),_c('h4',{staticClass:"flix-html-h4",staticStyle:{"margin-top":"50px"}},[_vm._v(" "+_vm._s(_vm.$t('overview.title'))+" ")]),_c('ul',{staticClass:"flix-list-group"},_vm._l((_vm.$t('overview.benefits')),function(arg){return _c('li',{key:arg,staticClass:"flix-list-group-item"},[_c('flixIcon',{attrs:{"id":"ok"}}),_vm._v(" "+_vm._s(arg)+" ")],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }